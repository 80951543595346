<template>
  <div v-if="!(pano.isPro&&pano.hideAround)">
    <template v-if="aroundPanos.length>0">
      <div class="text-shadow" style="margin-left:5px">
        <span v-if="pano.gpsLat!=0||pano.gpsLng!=0">附近全景</span>
        <span v-else>推荐全景</span>
      </div>
      <div class="no-scrollbar text-shadow background" style="">

        <ul class="list-unstyle">
          <li v-for="(item,index) in aroundPanos" class="hotspotItem" style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;position:relative;">
            <div class="picback">
            </div>
            <div style="position:relative">
              <nuxt-link :to="getRoute(item)" style="color:#fff" :title="item.title||'未命名'">
                <span v-if="pano.gpsLat!=0||pano.gpsLng!=0" class="text-shadow badge">{{getDistance(pano.gpsLat,pano.gpsLng,item.gpsLat,item.gpsLng)}}</span>
                <div style="text-align:center;font-size:0;margin: 2px;">
                  <div style="width:50px;height:50px;background-color:#ccc;display: inline-block;background-position:center;background-size:cover" :style="makeUrlStyle(((item.firstImage||{url:''}).url||'').replace('/0/0/0/0/', '/200/0/0/0/'))">
                  </div>
                </div>
                <div class="text-truncate" style="text-align:center;font-size:8px;position:absolute;bottom: 0px;left: 0;right: 0;">
                  {{item.title||'未命名'}}
                </div>
              </nuxt-link>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>
<script>
  import routeTemplate from '../../../libs/routeTemplate'
  import gpsCorrect from '../../../libs/gpsCorrect.js'
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        aroundPanos: [],
        loading: false,
      }
    },
    inject: {
      publicData: {
        default: {}
      }
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.loadAroundPanos()
    },
    destroyed() {
    },
    methods: {
      loadAroundPanos() {
        if (process.client) {
          if (this.pano.gpsLng != 0 || this.pano.gpsLat != 0) {
            this.loading = true
            var url = process.env.EnableElasticSearch ? `/Api/ES/Panos` : `/Api/Panos/AroundPanos`
            this.$axios.get(url, {
              params: {
                page: 1,
                pageSize: 11,
                lng: this.pano.gpsLng,
                lat: this.pano.gpsLat,
                radius: 100000,
                notInn: true,
                ...this.config.params
              }
            }).then((res) => {
              this.aroundPanos = res.data.data
              for (var i in this.aroundPanos) {
                var item = this.aroundPanos[i]
                if (item.guid == this.pano.guid) {
                  this.aroundPanos.splice(i, 1)
                  break
                }
              }
              for (var i in this.aroundPanos) {
                var item = this.aroundPanos[i]
                this.aroundPanos[i].distance = this.getDistance(this.pano.gpsLat, this.pano.gpsLng, item.gpsLat, item.gpsLng)
              }
              this.loading = false
            })
          } else {
            this.loading = true
            var url = process.env.EnableElasticSearch ? `/Api/ES/Panos` : `/Api/Panos`
            this.$axios.get(url, {
              params: {
                page: 1,
                pageSize: 11,
                notInn: true,
                fine: true,
              }
            }).then((res) => {
              this.aroundPanos = res.data.data
              for (var i in this.aroundPanos) {
                var item = this.aroundPanos[i]
                if (item.guid == this.pano.guid) {
                  this.aroundPanos.splice(i, 1)
                  break
                }
              }
              this.loading = false
            })
          }
        }
      },
      getRoute(item) {
        if (this.config && this.config.routeTemplate) {
          return routeTemplate(this.config.routeTemplate)(item)
        }
        return { name: 'PanoView', params: { panoId: item.key } }
      },
      getDistance(lat1, lng1, lat2, lng2) {
        var km = gpsCorrect.getDistance(lat1, lng1, lat2, lng2)
        if (km < 1) {
          return `${(km * 1000).toFixed(0)}m`
        }
        if (km < 10) {
          var str = `${(km).toFixed(1)}km`
          str = str.replace('.0', '')
          return str
        }
        return `${(km).toFixed(0)}km`
      },
      makeUrlStyle(url) {
        return {
          'background-image': "url('" + url + "')"
        }
      },
    },
  }
</script>
<style scoped>
  .background {
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
    overflow: auto;
    opacity: 0.8;
    background-color: #0005
  }

  .badge {
    display: inline-block;
    padding: 0.25em;
    font-size: 8px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    position: absolute;
    top: 0;
    right: 0;
  }

  .picback {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fffa;
    box-shadow: #0005 2px;
    box-shadow: #000 0 0 2px;
  }

    .picback.active {
      background-color: #ffbb00aa;
    }
</style>
